.App {
  text-align: center;
}

.home-link {
  width: 64px;
  height: 64px;
  display: flex;
  fill: white;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.home-link:hover {
  fill: #BA9860;
}