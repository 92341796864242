.pool-card {
    display: flex;
    width: 100%;
    background: rgb(28, 28, 28);
    border: none;
    border-radius: 8px;
    overflow: hidden;
    margin: 12px 0;
    box-sizing: content-box;
}

.pool-card-content {
    padding: 18px 36px 18px 18px;
    flex-grow: 1;
}

.pool-card-dropdown {
    background:rgb(28, 28, 28);
    margin-top: -18px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: height linear 0.5s;
    margin-bottom: 8px;
}

.pool-card-dropdown-content {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.pool-card-assets-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    max-width: 180px;
    padding: 0;
    transition: opacity ease-in-out 0.5s;
    min-height: 120px;
    margin-left: 24px;
}

.pool-card-asset {
    display: flex;
    justify-content: space-between;
    margin: 2px 8px;
}

.pool-card-asset-symbol {
    min-width: '80px'
}

.pool-card-asset-percent {
    display: flex;
    text-align: right;
}

.pool-card-actions {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-right: 24px;
}

.pool-card-action {
    margin: 6px 0;
}

.pool-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.pool-modal-content { 
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.deposit-modal-input {
    text-align: center;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 12px;
    border: none;
    background: rgba(60,60,60);
    color: white;
}

.deposit-modal-input:focus-within {
    box-shadow: 0 0 2px 2px #ba976085;
}

.q-input {
    align-self: center;
    outline: none;
    border: none;
    color: white;
    background: none;
    text-align: right;
    font-size: 16px;
    display: flex;
    width: calc(72% - 24px);
    padding: 12px 0;
    margin: 0 12px;
}

.q-input-token {
    background: black;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.075em;
}