.staking-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    border-radius: 8px;
    background: rgb(28, 28, 28);
    padding: 12px 24px;
    margin: 12px 0;
    min-height: 220px;
}

.staking-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.staking-card-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid rgba(96, 96, 96, 0.3);
    box-sizing: border-box;
}

.staking-card-actions {
    display: flex;
    justify-content: flex-end;
    align-self: center;
    flex-grow: 1;
}

.staking-card-button {
    outline: none;
    border: none;
    border-radius: 4px;
    background: rgb(12, 12, 12);
    padding: 8px;
    width: 100px;
    cursor: pointer;
    color: #BA9860;
}

.staking-card-button:first-child {
    margin-right: 12px;
}

.stake-button {
    background: #BA9860;
    color: black;
    outline: none;
    border: none;
    border-radius: 4px;
    padding: 8px;
    width: 100px;
    cursor: pointer;
}

.stake-button:hover {
    box-shadow: 0 0 2px 1px #BA9860;
}

.stake-button:active {
    background:rgba(186, 151, 96, 0.603);
    box-shadow: 0 0 2px 1px rgba(186, 151, 96, 0.603);
}

.button-disabled {
    background: rgba(96, 96, 96, 0.9);
    color: darkgrey;
    cursor: auto;
}

.staking-card-button:hover {
    box-shadow: 0 0 2px 1px rgba(12, 12, 12);
}

.button-disabled:hover {
    box-shadow: 0 0 0 0 !important;
}