.modal {
    z-index: 999;
    background: rgb(28, 28, 28);
    position: fixed;
    min-height: 300px;
    max-height: 400px;
    overflow: hidden;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 340px;
    border-radius: 8px;
    box-shadow: 0 0 0 100vmax #020202c4;
}

.modal-shell {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: calc(300px - 72px);
    justify-content: space-between;
    align-items: center;
    padding: 36px 24px 0px 24px;
}

.modal-close {
    position: fixed;
    top: 12px;
    right: 12px;
    border-radius: 8px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-close:hover {
    color: #BA9860;
    cursor: pointer;
}

.modal-header {
    padding: 12px 24px;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: white;
}

.modal-actions {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.modal-action-button {
    outline: none;
    background: rgba(60, 60, 60);
    border-radius: 8px;
    width: 96px;
    border: none;
    outline: none;
    padding: 8px 0;
    color: #BA9860;
    flex-grow: 1;
    cursor: pointer;
}

.modal-action-button:hover {
    box-shadow: 0 0 8px 2px rgba(48, 48, 48, 0.7);
}

.modal-action-button:active {
    background: rgba(36,36,36);
    box-shadow: 0 0 0 0 rgba(60,60,60);
}

.modal-action-button:disabled {
    cursor: auto;
    box-shadow: 0 0 0 0 !important;
    color: grey;
}