@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.home-link {
  width: 64px;
  height: 64px;
  display: flex;
  fill: white;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.home-link:hover {
  fill: #BA9860;
}
.navigation-bar {
    display: flex;
    width: 100%;
    height: 64px;
    background: rgb(18, 18, 18);
    position: sticky;
    top: 0;
}

.nav-content {
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 24px;
    flex-grow: 1;
}

.nav-content-zone {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

.nav-brand {
    padding: 0 18px 0 0;
    border-right: 1px solid rgba(120,120,120,0.9);
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-image {
    position: relative;
    top: 2px;
    margin: 0;
    padding: 0;
}

.nav-label {
    margin-left: 4px;
}

.nav-link {
    display: flex;
    height: 100%;
    max-height: 64px;
    align-items: center;
    justify-content: center;
    padding: 0 18px;
    border-left: 1px solid rgba(120,120,120,0.9);
    color: inherit;
    text-decoration: none;
    text-align: center;
    border: none;
}

.nav-link:hover {
    background:rgba(28, 28, 28, 0.75);
}

.navlink-selected {
    color: #BA9860;
    background:rgba(28, 28, 28, 0.75);
}

.nav-account {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    cursor: pointer;
    width: 180px;
    padding: 0 8px;
}

.nav-account:hover {
    background:rgba(28, 28, 28, 0.75);
}

.nav-account:active {
    background: rgba(16, 16, 16);
    transition: background linear 0.2s;
}

.nav-tx-tracker {
    display: flex;
    width: 24px;
    justify-content: flex-start;
    margin-right: 8px;
}

.nav-indicator {
    height: 14px;
    width: 14px;
    border-radius: 45px;
    margin-right: 16px;
}

.nav-account-dropdown {
    background: rgb(18, 18, 18);
    overflow: hidden;
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 64px;
    right: 0;
    width: 196px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: height ease-in-out 0.3s;
}

.connect-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
}

.connect-provider-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;
}

.connect-provider {
    display: flex;
    height: 42px;
    width: 100%;
    padding: 8px;
    margin: 8px 0;
    border-radius: 8px;
    cursor: pointer;
    align-items: center;
}

.connect-provider img {
    margin-right: 12px;
}

.connect-provider:hover {
    box-shadow: 0 0 2px 2px #ba97603a;
}

.nav-dropdown-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 28px);
    flex-grow: 1;
    padding: 14px;
}

.account-balances {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 14px;
}

.account-balance-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    align-items: center;
    flex-grow: 1;
}

.account-actions {
    font-size: 14px;
    display: flex;
    justify-content: center;
}

.account-action-button {
    cursor: pointer;
}

.account-action-button:hover {
    color: #BA9860;
}

.mobile-nav-dropdown {
    position: fixed;
    top: 64px;
    width: 100%;
    background: rgb(18,18,18);
    overflow: hidden;
}

.tx-history-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    width: calc(100% - 24px);
    padding: 12px;
    height: 200px;
}

.tx-history-list {
    flex-grow: 1;
    margin: 12px 0;
}
.modal {
    z-index: 999;
    background: rgb(28, 28, 28);
    position: fixed;
    min-height: 300px;
    max-height: 400px;
    overflow: hidden;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 340px;
    border-radius: 8px;
    box-shadow: 0 0 0 100vmax #020202c4;
}

.modal-shell {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: calc(300px - 72px);
    justify-content: space-between;
    align-items: center;
    padding: 36px 24px 0px 24px;
}

.modal-close {
    position: fixed;
    top: 12px;
    right: 12px;
    border-radius: 8px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-close:hover {
    color: #BA9860;
    cursor: pointer;
}

.modal-header {
    padding: 12px 24px;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: white;
}

.modal-actions {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.modal-action-button {
    outline: none;
    background: rgba(60, 60, 60);
    border-radius: 8px;
    width: 96px;
    border: none;
    outline: none;
    padding: 8px 0;
    color: #BA9860;
    flex-grow: 1;
    cursor: pointer;
}

.modal-action-button:hover {
    box-shadow: 0 0 8px 2px rgba(48, 48, 48, 0.7);
}

.modal-action-button:active {
    background: rgba(36,36,36);
    box-shadow: 0 0 0 0 rgba(60,60,60);
}

.modal-action-button:disabled {
    cursor: auto;
    box-shadow: 0 0 0 0 !important;
    color: grey;
}
.pool-card {
    display: flex;
    width: 100%;
    background: rgb(28, 28, 28);
    border: none;
    border-radius: 8px;
    overflow: hidden;
    margin: 12px 0;
    box-sizing: content-box;
}

.pool-card-content {
    padding: 18px 36px 18px 18px;
    flex-grow: 1;
}

.pool-card-dropdown {
    background:rgb(28, 28, 28);
    margin-top: -18px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: height linear 0.5s;
    margin-bottom: 8px;
}

.pool-card-dropdown-content {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.pool-card-assets-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    max-width: 180px;
    padding: 0;
    transition: opacity ease-in-out 0.5s;
    min-height: 120px;
    margin-left: 24px;
}

.pool-card-asset {
    display: flex;
    justify-content: space-between;
    margin: 2px 8px;
}

.pool-card-asset-symbol {
    min-width: '80px'
}

.pool-card-asset-percent {
    display: flex;
    text-align: right;
}

.pool-card-actions {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-right: 24px;
}

.pool-card-action {
    margin: 6px 0;
}

.pool-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.pool-modal-content { 
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.deposit-modal-input {
    text-align: center;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 12px;
    border: none;
    background: rgba(60,60,60);
    color: white;
}

.deposit-modal-input:focus-within {
    box-shadow: 0 0 2px 2px #ba976085;
}

.q-input {
    align-self: center;
    outline: none;
    border: none;
    color: white;
    background: none;
    text-align: right;
    font-size: 16px;
    display: flex;
    width: calc(72% - 24px);
    padding: 12px 0;
    margin: 0 12px;
}

.q-input-token {
    background: black;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.075em;
}
.toggle-nav {
    display: flex;
    justify-content: space-evenly;
    background: rgb(28,28,28);
    height: 48px;
    border-radius: 8px;
    margin-bottom: 12px;
    overflow: hidden;
}

.toggle-nav-item {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
    cursor: pointer;
}

.toggle-nav-item-selected {
    background: #BA9860;
    color: black;
    cursor: auto;
}
.staking-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    border-radius: 8px;
    background: rgb(28, 28, 28);
    padding: 12px 24px;
    margin: 12px 0;
    min-height: 220px;
}

.staking-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.staking-card-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid rgba(96, 96, 96, 0.3);
    box-sizing: border-box;
}

.staking-card-actions {
    display: flex;
    justify-content: flex-end;
    align-self: center;
    flex-grow: 1;
}

.staking-card-button {
    outline: none;
    border: none;
    border-radius: 4px;
    background: rgb(12, 12, 12);
    padding: 8px;
    width: 100px;
    cursor: pointer;
    color: #BA9860;
}

.staking-card-button:first-child {
    margin-right: 12px;
}

.stake-button {
    background: #BA9860;
    color: black;
    outline: none;
    border: none;
    border-radius: 4px;
    padding: 8px;
    width: 100px;
    cursor: pointer;
}

.stake-button:hover {
    box-shadow: 0 0 2px 1px #BA9860;
}

.stake-button:active {
    background:rgba(186, 151, 96, 0.603);
    box-shadow: 0 0 2px 1px rgba(186, 151, 96, 0.603);
}

.button-disabled {
    background: rgba(96, 96, 96, 0.9);
    color: darkgrey;
    cursor: auto;
}

.staking-card-button:hover {
    box-shadow: 0 0 2px 1px rgba(12, 12, 12);
}

.button-disabled:hover {
    box-shadow: 0 0 0 0 !important;
}
