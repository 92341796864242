.navigation-bar {
    display: flex;
    width: 100%;
    height: 64px;
    background: rgb(18, 18, 18);
    position: sticky;
    top: 0;
}

.nav-content {
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 24px;
    flex-grow: 1;
}

.nav-content-zone {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

.nav-brand {
    padding: 0 18px 0 0;
    border-right: 1px solid rgba(120,120,120,0.9);
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-image {
    position: relative;
    top: 2px;
    margin: 0;
    padding: 0;
}

.nav-label {
    margin-left: 4px;
}

.nav-link {
    display: flex;
    height: 100%;
    max-height: 64px;
    align-items: center;
    justify-content: center;
    padding: 0 18px;
    border-left: 1px solid rgba(120,120,120,0.9);
    color: inherit;
    text-decoration: none;
    text-align: center;
    border: none;
}

.nav-link:hover {
    background:rgba(28, 28, 28, 0.75);
}

.navlink-selected {
    color: #BA9860;
    background:rgba(28, 28, 28, 0.75);
}

.nav-account {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    cursor: pointer;
    width: 180px;
    padding: 0 8px;
}

.nav-account:hover {
    background:rgba(28, 28, 28, 0.75);
}

.nav-account:active {
    background: rgba(16, 16, 16);
    transition: background linear 0.2s;
}

.nav-tx-tracker {
    display: flex;
    width: 24px;
    justify-content: flex-start;
    margin-right: 8px;
}

.nav-indicator {
    height: 14px;
    width: 14px;
    border-radius: 45px;
    margin-right: 16px;
}

.nav-account-dropdown {
    background: rgb(18, 18, 18);
    overflow: hidden;
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 64px;
    right: 0;
    width: 196px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: height ease-in-out 0.3s;
}

.connect-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
}

.connect-provider-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;
}

.connect-provider {
    display: flex;
    height: 42px;
    width: 100%;
    padding: 8px;
    margin: 8px 0;
    border-radius: 8px;
    cursor: pointer;
    align-items: center;
}

.connect-provider img {
    margin-right: 12px;
}

.connect-provider:hover {
    box-shadow: 0 0 2px 2px #ba97603a;
}

.nav-dropdown-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 28px);
    flex-grow: 1;
    padding: 14px;
}

.account-balances {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 14px;
}

.account-balance-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    align-items: center;
    flex-grow: 1;
}

.account-actions {
    font-size: 14px;
    display: flex;
    justify-content: center;
}

.account-action-button {
    cursor: pointer;
}

.account-action-button:hover {
    color: #BA9860;
}

.mobile-nav-dropdown {
    position: fixed;
    top: 64px;
    width: 100%;
    background: rgb(18,18,18);
    overflow: hidden;
}

.tx-history-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    width: calc(100% - 24px);
    padding: 12px;
    height: 200px;
}

.tx-history-list {
    flex-grow: 1;
    margin: 12px 0;
}